import { useContext, useEffect, useRef, useState } from "react";
import LangContext from "./LangContext";
import pdfIcon from "../images/pdf.svg";
import pptIcon from "../images/ppt.svg";
import officeIcon from "../images/office.svg";
import phoneIcon from "../images/phone.svg";
import faxIcon from "../images/fax.svg";
import emailIcon from "../images/email.svg";
import webIcon from "../images/website.svg";
import "./Basic.sass";
import {ProfessorDetail, ProfessorCurrentResearch, ProfessorBiography, ProfessorHonors, ProfessorPublication, ProfessorService} from "./ProfessorDetail";

function Container({ active, fill, full, padding, children }) {
    return (
        <div
            className="access-container"
            active={active ? "" : null}
            fill={fill}
            full={full ? "" : null}
            padding={padding}
        >
            {children}
        </div>
    );
}

function Spacer({ active, fill, height }) {
    return (
        <div
            className="access-spacer"
            active={active ? "" : null}
            fill={fill}
            style={{ height: height }}
        />
    );
}

function Text({ active, fill, color, size, align, paddingTop, paddingBottom, paddingLeft, paddingRight, selectable, children }) {
    const breakpoint = /\[pdf=[^\[\]]+\]|\[ppt=[^\[\]]+\]|\[link=[^\[\]]+\]|\[[brlin]\]/g;
    const pdfBreakpoint = /\[pdf=([^\[\]]+)\]/g;
    const pptBreakpoint = /\[ppt=([^\[\]]+)\]/g;
    const linkBreakpoint = /\[link=([^\[\]]+)\]/g;
    const text = "[r][n]" + children;
    const format = text.match(breakpoint);
    const spans = text.split(breakpoint).map((e, i) => [e, i == 0 ? "[r]" : format[i - 1]]);

    let weight = "regular";
    let style = "normal";
    return (
        <div
            className="access-text"
            active={active ? "" : null}
            fill={fill}
            color={color}
            align={align}
            selectable={selectable ? "" : null}
            style={{
                fontSize: size,
                paddingTop: paddingTop,
                paddingBottom: paddingBottom,
                paddingLeft: paddingLeft ?? "32px",
                paddingRight: paddingRight ?? "32px"
            }}
        >
            {spans.map((el, idx) => {
                if (el[1].match(pdfBreakpoint)) {
                    const url = el[1].replace(pdfBreakpoint, "$1");
                    return (
                        <span key={idx}>
                            <a href={url} target="_blank"><img src={pdfIcon} /></a>
                            <span className={`${weight} ${style}`}>{el[0]}</span>
                        </span>
                    );
                } else if (el[1].match(pptBreakpoint)) {
                    const url = el[1].replace(pptBreakpoint, "$1");
                    return (
                        <span key={idx}>
                            <a href={url} target="_blank"><img src={pptIcon} /></a>
                            <span className={`${weight} ${style}`}>{el[0]}</span>
                        </span>
                    );
                } else if (el[1].match(linkBreakpoint)) {
                    const url = el[1].replace(linkBreakpoint, "$1");
                    return (
                        <span key={idx}>
                            <a href={url} target="_blank">
                                <span className={`${weight} ${style}`}>{el[0]}</span>
                            </a>
                        </span>
                    );
                } else {
                    switch (el[1]) {
                        case "[b]":
                            weight = "bold";
                            break;
                        case "[l]":
                            weight = "light";
                            break;
                        case "[r]":
                            weight = "regular";
                            break;
                        case "[i]":
                            style = "italic";
                            break;
                        case "[n]":
                            style = "normal";
                            break;
                    }
                    return (
                        el[0] != "" &&
                        <span key={idx} className={`${weight} ${style}`}>
                            {el[0]}
                        </span>
                    );
                }
            })}
        </div>
    );
}

function Image({ active, fill, srcs, columns, gap, width, align, paddingTop, paddingBottom, paddingLeft, paddingRight }) {
    srcs = Array.isArray(srcs) ? srcs : [srcs];
    columns = `repeat(${columns ?? 1}, 1fr)`;
    const imgRef = srcs.map(_ => useRef(null));

    return (
        <div
            className="access-image"
            active={active ? "" : null}
            fill={fill}
        >
            <div
                align={align}
                style={{ width: width }}
            >
                <div
                    style={{
                        paddingTop: paddingTop,
                        paddingBottom: paddingBottom,
                        paddingLeft: paddingLeft,
                        paddingRight: paddingRight,
                        gridTemplateColumns: columns,
                        gridGap: gap
                    }}
                >
                    {srcs.map((src, idx) => (
                        <img
                            key={idx}
                            src={src}
                            ref={imgRef[idx]}
                            onLoad={_ => imgRef[idx].current.setAttribute("active", "")}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

function TextedImage({ active, fill, width, textConfig, imageConfig }) {
    const text = (
        <div
            role="text"
            style={{ width: `calc(100% - ${width ?? "50%"})` }}
        >
            <Text
                active={true}
                color={textConfig?.color}
                size={textConfig?.size}
                align={textConfig?.align}
                paddingTop={textConfig?.padding_top}
                paddingBottom={textConfig?.padding_bottom}
                paddingLeft={textConfig?.padding_left}
                paddingRight={textConfig?.padding_right}
                selectable={textConfig?.selectable}
            >{textConfig?.content}</Text>
        </div>
    );
    const image = (
        <div
            role="image"
            style={{ width: width }}
        >
            <Image
                active={true}
                srcs={imageConfig?.srcs}
                columns={imageConfig?.columns}
                gap={imageConfig?.gap}
                width={imageConfig?.width}
                align={imageConfig?.align}
                paddingTop={imageConfig?.padding_top}
                paddingBottom={imageConfig?.padding_bottom}
                paddingLeft={imageConfig?.padding_left}
                paddingRight={imageConfig?.padding_right}
            />
        </div>
    );
    return (
        <div
            className="access-texted-image"
            active={active ? "" : null}
            fill={fill}
        >
            {imageConfig?.direction == "left" ?
                <>
                    {image}
                    {text}
                </> : <>
                    {text}
                    {image}
                </>
            }
        </div>
    );
}

function InfoCard({ active, fill, title, description, descriptionColor, descriptionAlign }) {
    return (
        <div
            active={active ? "" : null}
            className="access-infocard"
            fill={fill}
        >
            <div role="title">{title}</div>
            <div role="description">
                <Text
                    active={true}
                    color={descriptionColor}
                    align={descriptionAlign}
                    size={18}
                >{description}</Text>
            </div>
        </div>
    );
}

function InfoGroup({ active, fill, title, contents }) {
    return (
        <div
            active={active ? "" : null}
            className="access-infogroup"
            fill={fill}
        >
            <div role="title">{title}</div>
            {contents?.map((content, idx) => (
                <InfoCard
                    key={idx}
                    active={true}
                    title={content.title}
                    description={content.description}
                    descriptionColor={content.color}
                    descriptionAlign={content.align}
                />
            ))}
        </div>
    )
}

function ProfessorInfo({ active, fill }) {
    const lang = useContext(LangContext);
    const [content, setContent] = useState(null);
    active = (content == null || !active) ? null : "";

    useEffect(() => {
        if (process.env.NODE_ENV !== "production") {
            // Inject fake data
            setTimeout(_ => {
                setContent(
                    lang == "en" ? {
                        "img": "http://140.112.20.133:8081/images/andy.png",
                        "name": "Prof. An-Yeu (Andy) Wu",
                        "titles": ["Distinguished Professor", "IEEE Fellow"],
                        "description": "Department of Electrical Engineering &\nGraduate Institute of Electronics Engineering\n[b]National Taiwan University",
                        "address": "EE2-441, Department of Electrical Engineering, No.1, Sec.4, Roosevelt Road, Taipei 106, Taiwan",
                        "phone": "+886-2-3366-3641",
                        "fax": "+886-2-2365-6337",
                        "email": "andywu@ntu.edu.tw",
                        "website": "http://access.ee.ntu.edu.tw"
                    } : {
                        "img": "http://140.112.20.133:8081/images/andy.png",
                        "name": "吳安宇 An-Yeu (Andy) Wu",
                        "titles": ["特聘教授", "IEEE Fellow"],
                        "description": "電機工程學系/電子所\n[b]國立台灣大學",
                        "address": "國立台灣大學電機系BL-417室\n台北市羅斯福路四段1號-博理館",
                        "phone": "(02)3366-3641",
                        "fax": "(02)2365-6337",
                        "email": "andywu@ntu.edu.tw",
                        "website": "http://access.ee.ntu.edu.tw"
                    }
                );
            }, 200);
        } else {
            fetch(`/api/professor_info/professor_info.json?lang=${lang}`)
                .then(res => {
                    if (res.ok) return res.json();
                    throw new Error("Broke professor_info.json request");
                })
                .then(json => setContent(json))
                .catch(e => console.error(e));
        }
    }, [lang]);

    const icon_data_table = [
        { icon: officeIcon, text: content?.address },
        { icon: phoneIcon, text: content?.phone },
        { icon: faxIcon, text: content?.fax },
        { icon: emailIcon, text: content?.email, url: `mailto:${content?.email}` },
        { icon: webIcon, text: content?.website, url: content?.website }
    ];

    return (
        <div
            active={active}
            className="access-professor-info"
            full=""
            fill={fill}
        >
            <div role="image">
                <Image
                    active={true}
                    srcs={content?.img}
                    columns={1}
                    width="100%"
                    paddingLeft="0"
                />
            </div>
            <div role="text">
                <div role="name_title">
                    <div role="name">{content?.name}</div>
                    {content?.titles?.map((title, idx) => (
                        <div
                            key={idx}
                            role="title"
                        >
                            {title}
                        </div>
                    ))}
                </div>
                <Text
                    active={true}
                    align="center"
                    size={18}
                >
                    {content?.description}
                </Text>
                <table>
                    <tbody>
                        {icon_data_table.map((data, idx) => (
                            <tr key={`tr${idx}`}>
                                <td key={`icon${idx}`}><img src={data.icon} /></td>
                                <td key={`text${idx}`}>{
                                    data.url ?
                                        <a href={data.url}><span>{data.text}</span></a> :
                                        <span>{data.text}</span>
                                }
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

function ElementRenderer({ active, full, contents }) {
    return (
        <Container
            active={active}
            full={full}
        >
            {contents?.map((content, idx) => {
                const type = content.type;
                const config = content.config;
                switch (type) {
                    case "spacer":
                        return config?.multiple != null ? (
                            config.multiple?.map((config, idx_) => (
                                <Spacer
                                    key={`${idx}-${idx_}`}
                                    active
                                    fill={config?.fill}
                                    height={config?.height}
                                />
                            ))
                        ) : (
                            <Spacer
                                key={idx}
                                active
                                fill={config?.fill}
                                height={config?.height}
                            />
                        );
                    case "text":
                        return (
                            <Text
                                key={idx}
                                active
                                fill={config?.fill}
                                color={config?.color}
                                size={config?.size}
                                align={config?.align}
                                paddingTop={config?.padding_top}
                                paddingBottom={config?.padding_bottom}
                                paddingLeft={config?.padding_left}
                                paddingRight={config?.padding_right}
                                selectable={config?.selectable}
                            >{config?.content}</Text>
                        );
                    case "image":
                        return (
                            <Image
                                key={idx}
                                active
                                fill={config?.fill}
                                srcs={config?.srcs}
                                columns={config?.columns}
                                gap={config?.gap}
                                width={config?.width}
                                align={config?.align}
                                paddingTop={config?.padding_top}
                                paddingBottom={config?.padding_bottom}
                                paddingLeft={config?.padding_left}
                                paddingRight={config?.padding_right}
                            />
                        );
                    case "texted_image":
                        return (
                            <TextedImage
                                key={idx}
                                active
                                fill={config?.fill}
                                width={config?.image_width}
                                textConfig={config?.text}
                                imageConfig={config?.image}
                            />
                        );
                    case "professor_info":
                        return (
                            <ProfessorInfo
                                key={idx}
                                active={true}
                                fill={config?.fill}
                            />
                        );
                    case "professor_detail":
                        if (config?.content == null || !Array.isArray(config?.content) || config?.content.length == 0)
                            return null;
                        else
                            return (
                                <ProfessorDetail key={idx}
                                    content={config?.content}
                                />
                            );
                    case "professor_current_research":
                        return <ProfessorCurrentResearch key={idx} />
                    case "professor_biography":
                        return <ProfessorBiography key={idx} />
                    case "professor_honors":
                        return <ProfessorHonors key={idx} />
                    case "professor_publications":
                        return <ProfessorPublication key={idx} />
                    case "professor_service":
                        return <ProfessorService key={idx} />
                }
            })}
        </Container>
    );
}

function SimpleElementRenderer({ active, fill, full, padding, contents }) {
    return (
        <Container
            active={active}
            fill={fill}
            full={full}
            padding={padding}
        >
            {contents?.map((content, idx) => {
                const type = content.type;
                const config = content.config;
                switch (type) {
                    case "spacer":
                        return config?.multiple != null ? (
                            config.multiple?.map((config, idx_) => (
                                <Spacer
                                    key={`${idx}-${idx_}`}
                                    active
                                    fill={config?.fill}
                                    height={config?.height}
                                />
                            ))
                        ) : (
                            <Spacer
                                key={idx}
                                active
                                fill={config?.fill}
                                height={config?.height}
                            />
                        );
                    case "text":
                        return (
                            <Text
                                key={idx}
                                active
                                fill={config?.fill}
                                color={config?.color}
                                size={config?.size}
                                align={config?.align}
                                paddingTop={config?.padding_top}
                                paddingBottom={config?.padding_bottom}
                                paddingLeft={config?.padding_left}
                                paddingRight={config?.padding_right}
                                selectable={config?.selectable}
                            >{config?.content}</Text>
                        );
                    case "image":
                        return (
                            <Image
                                key={idx}
                                active
                                fill={config?.fill}
                                srcs={config?.srcs}
                                columns={config?.columns}
                                gap={config?.gap}
                                width={config?.width}
                                align={config?.align}
                                paddingTop={config?.padding_top}
                                paddingBottom={config?.padding_bottom}
                                paddingLeft={config?.padding_left}
                                paddingRight={config?.padding_right}
                            />
                        );
                    case "texted_image":
                        return (
                            <TextedImage
                                key={idx}
                                active
                                fill={config?.fill}
                                width={config?.image_width}
                                textConfig={config?.text}
                                imageConfig={config?.image}
                            />
                        );
                }
            })}
        </Container>
    );
}

export { Container, Spacer, Text, Image, TextedImage, InfoCard, InfoGroup, ElementRenderer, SimpleElementRenderer };